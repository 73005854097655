body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Work Sans', 'Karla',
    'Lato' sans-serif;
}

div[role='button'] {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

a {
  cursor: pointer;
}

div,
section,
article {
  box-sizing: border-box;
}

body {
  background-color: #e5e5e5;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
